<template>
  <div>
    <!-- CREATIVE LARGE PREVIEW -->
    <div class="creative-large-preview text-start">
      <!-- HEADING -->
      <div class="d-flex justify-content-start align-items-center mb-3">
        <div class="logo me-3">
          <img :src="logoImageUrl" width="50" :alt="$t('logo')" />
        </div>
        <span class="fw-bold font-size-regular">{{ advertiserDisplayName }}</span>
      </div><!-- END HEADING -->
      <!-- IMAGE -->
      <div>
        <img :src="creativeImageUrl" alt="Creative" />
      </div> <!-- END IMAGE -->
      <!-- CONTENT -->
      <div class="p-3 pb-0">
        <span class="d-block fw-bold font-size-regular">{{ headline }}</span>
        <span class="font-size-small">{{ bodyText }}</span>
        <div class="d-flex justify-content-between align-items-center mt-3">
          <span class="font-size-small">{{ url }}</span>
          <div>
            <button type="button" class="btn btn-primary btn-xs">{{ callToAction }}</button>
          </div>
        </div>
      </div> <!-- END CONTENT -->
    </div> <!-- END CREATIVE LARGE PREVIEW -->
    <div class="">
      <hr class="my-4 bg-color2 w-75 mx-auto" />
    </div>
    <!-- CREATIVE SMALL PREVIEW -->
    <div class="creative-small-preview text-start">
      <div class="d-flex">
        <!-- IMAGE -->
        <div class="col-5">
          <img :src="creativeImageUrl" alt="Creative" />
        </div> <!-- END IMAGE -->
        <!-- CONTENT -->
        <div class="col-7 ps-2">
          <span class="d-block fw-bold font-size-regular">{{ headline }}</span>
          <span class="font-size-small">{{ bodyText }}</span>
          <div class="text-end">
            <span class="font-size-small d-flex align-items-center justify-content-end mt-2">{{ $t('sponsored_by') }} <img :src="logoImageUrl" width="15" :alt="$t('logo')" class="ms-1" /></span>
          </div>
        </div> <!-- END CONTENT -->
      </div>
    </div> <!-- END CREATIVE SMALL PREVIEW -->
    <p class="font-size-small mt-4"><strong>{{ $t('attention') }}:</strong> {{ $t('info_illustrative_design_preview') }}</p>
  </div>
</template>

<script>
export default {
  props: ['advertiser', 'creative', 'form'],
  name: 'CreativePreview',
  computed: {
    /**
     * Body text
     */
    advertiserDisplayName () {
      if (this.form && this.form.advertiser_display_name) return this.form.advertiser_display_name
      if (this.creative && this.creative.advertiser_display_name) return this.creative.advertiser_display_name
      return this.advertiser ? this.advertiser.name : this.$t('advertiser_name')
    },
    /**
     * Body text
     */
    bodyText () {
      if (this.form && this.form.body_text) return this.form.body_text
      if (this.creative && this.creative.body_text) return this.creative.body_text
      return this.$t('body_text')
    },
    /**
     * CTA
     */
    callToAction () {
      if (this.form && this.form.call_to_action) return this.form.call_to_action
      if (this.creative && this.creative.call_to_action) return this.creative.call_to_action
      return this.$t('call_to_action')
    },
    /**
     * Creative image url
     */
    creativeImageUrl () {
      if (this.form && this.form.file) return URL.createObjectURL(this.form.file)
      if (this.creative && this.creative.file) return this.creative.file
      return '/images/common/placeholders/1200x627.png'
    },
    /**
     * Headline
     */
    headline () {
      if (this.form && this.form.headline) return this.form.headline
      if (this.creative && this.creative.headline) return this.creative.headline
      return this.$t('title')
    },
    /**
     * Logo image URL
     */
    logoImageUrl () {
      if (this.form && this.form.logo) return URL.createObjectURL(this.form.logo)
      if (this.creative && this.creative.logo) return this.creative.logo
      return '/images/common/placeholders/200x200.png'
    },
    /**
     * URL
     */
    url () {
      if (this.form && this.form.caption_url) return this.form.caption_url
      if (this.creative && this.creative.caption_url) return this.creative.caption_url
      return this.$t('example_site')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
