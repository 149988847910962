var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[((
      _vm.form &&
      (
        _vm.form.format === 'NATIVE_DISPLAY' ||
        _vm.form.format === 'PUSH'
      )
    ) ||
    (
      _vm.creative && (
        _vm.creative.format === 'NATIVE_DISPLAY' ||
        _vm.creative.format === 'PUSH'
      )
    ))?_c('NativeCreativePreview',{attrs:{"advertiser":_vm.advertiser,"creative":_vm.creative,"form":_vm.form}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }