<template>
  <div>
    <NativeCreativePreview
      :advertiser="advertiser"
      :creative="creative"
      :form="form"
      v-if="(
        form &&
        (
          form.format === 'NATIVE_DISPLAY' ||
          form.format === 'PUSH'
        )
      ) ||
      (
        creative && (
          creative.format === 'NATIVE_DISPLAY' ||
          creative.format === 'PUSH'
        )
      )"
    />
  </div>
</template>

<script>

import NativeCreativePreview from '@/components/creatives/CreativePreview/NativeCreativePreview'

export default {
  props: ['advertiser', 'creative', 'form'],
  name: 'CreativePreviewWrapper',
  components: {
    NativeCreativePreview
  }
}
</script>
